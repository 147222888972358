<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
    </base-header>

    <b-container fluid class="mt--7">
      <b-row>
        <b-col>
          <b-overlay :show="loading">
            <b-card no-body>
              <b-card-header class="border-0">
                <h3 class="mb-0">ノード一覧</h3>
              </b-card-header>
              <!-- Main table element -->
              <b-table
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                stacked="md"
                show-empty
                small
                hover
              >
                <template #cell(name)="row">
                  {{ row.value }}
                </template>
                <template #cell(is_active)="row">
                  <b-icon v-if="row.value" icon="check-circle-fill" variant="success"></b-icon>
                  <b-icon v-else icon="x-circle-fill" variant="danger"></b-icon>
                </template>
                <template #cell(actions)="row">
                  <b-button size="sm" variant="link" @click="info(row.item, $event.target)">
                    <b-icon icon="pencil-square" variant="primary"></b-icon>
                  </b-button>
                </template>

                <template #row-details="row">
                  <b-card>
                    <ul>
                      <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                    </ul>
                  </b-card>
                </template>
              </b-table>
              <b-card-footer>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  size="md"
                ></b-pagination>
              </b-card-footer>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="12">
        </b-col>
      </b-row>
    </b-container>
    <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal" @ok="saveNode">
      <base-form
        :schema="node_schema"
        :value="infoModal.node"
        ref="basci_info_form"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Toast } from '../../components';
import { ajax, common, config, constant } from '../../utils/index';
import { helper } from '../common';
import { node_form_schema } from '../workflow/schema';

export default {
  data() {
    return {
      loading: true,
      items: [],
      currentPage: 1,
      perPage: config.rowsPerPage,
      infoModal: {
        id: 'info-modal',
        title: '',
        node: '',
      },
      node_schema: node_form_schema,
    }
  },
  computed: {
    ...mapGetters('account', ['hasPerm']),
    fields: function() {
      const items = [
        { key: 'name', label: '名称', sortDirection: 'desc' },
        { key: 'is_active', label: '有効', class: 'text-center' },
      ];
      if (this.hasPerm('workflow.change_node')) {
        items.push({ key: 'actions', label: 'Actions', class: 'text-center' });
      }
      return items;
    },
    totalRows: function() {
      return this.items.length;
    },
  },
  created: function () {
    this.getWorkflowList();
  },
  methods: {
    getWorkflowList: function() {
      const vm = this;
      ajax.fetchGet(config.api.workflow.node_list).then(res => {
        vm.items = res.results;
      }).catch(errors => {
        helper.show_errors(errors);
      }).finally(() => {
        vm.loading = false;
      });
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    info(item, button) {
      this.infoModal.title = item.name;
      this.infoModal.node = item;
      this.$root.$emit('bv::show::modal', this.infoModal.id, button);
    },
    saveNode(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      const { id, node } = this.infoModal;
      common.loading();
      ajax.fetchPut(common.formatStr(config.api.workflow.node_detail, node.code), node).then(() => {
        this.$bvModal.hide(id);
        Toast.success(constant.SUCCESS.SAVED)
      }).catch(errors => {
        Toast.errorFromApi(errors)
      }).finally(() => {
        common.loaded();
      })
    },
  }
}
</script>