export const node_form_schema = [
  {
    "name": "name",
    "type": "string",
    "label": "名称",
    "read_only": true,
  },
  {
    "name": "is_active",
    "type": "boolean",
    "label": "有効",
    "required": true,
  },
];
